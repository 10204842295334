import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/functions'
import 'firebase/storage'
import 'firebase/analytics'

const firebaseConfig = {
  apiKey: "AIzaSyDK9sdhJpn5FCSiaEnHHMJ1UBSYe6z0a3A",
  authDomain: "getraenke-beefreund-7c291.firebaseapp.com",
  databaseURL: "https://getraenke-beefreund-7c291-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "getraenke-beefreund-7c291",
  storageBucket: "getraenke-beefreund-7c291.appspot.com",
  messagingSenderId: "607732676011",
  appId: "1:607732676011:web:040659efad688397bd0eee"
};

const fb = firebase.initializeApp(firebaseConfig)
const veri = fb.database()
const fonksiyon = fb.functions()
const depo = fb.storage()

firebase.analytics();

export {
  fb,
  veri,
  fonksiyon,
  depo,
}
